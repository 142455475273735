import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import EMLogo from "../components/EMLogo"
import heroImage from "../images/2V2A9745.jpg"
import youAreAwesomeGif from "../images/youareawesome.gif"

const GiveLovePage = () => (
  <Layout
    title="Empower + Rise: Give Love"
  >
    <section
      style={{
        background: 'url(' + heroImage + ')',
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="white overflow-hidden relative"
    >
      <div className="mt4 flex flex-column flex-row-ns justify-around items-center">
        <Link to="/">
          <EMLogo style={{ fill: "#fff", width: "160px" }} />
        </Link>
      </div>

      <div className="tc mv6 w-100">
        <h2 className="f-subheadline-ns f2 lh-title w-90 w-100-ns center">
          Together Is The Name <br className="dn dib-ns" /> Of The Game
        </h2>
      </div>

      <div className="pv3-ns">
        <div className="w-100 flex justify-center">
          <img className="awesome-gif" src={youAreAwesomeGif} />
        </div>
      </div>
    </section>

    <section className="pa5-ns black mh3 mb4-ns mt5-ns mv4 bg-white pt3 pb2 ph5-ns tc">
      <h2 className="f3 f1-ns lh-title mb3">
        Give and you shall receive, right? <br className="dn dib-ns" /> Give the
        gift of love.{" "}
      </h2>

      <div className="f4 f3-ns lh-copy tracked mv4  rust brandon-bold">
        COST: $0 <br /> RETURN: Priceless
      </div>

      <p className="f4 dib lh-copy brandon-light w-60-ns w-100">
        No, no, no. This isn’t a once a year only on valentine’s day kinda
        thing. It’s an all year, all the time, so important to give and receive
        love kinda thing. We Empower and Rise together... That’s why we want to
        invite you to write a love note to the thousands of other women who
        receive love notes daily. What do you need to hear right now? What would
        you say to love up your BFF? Every other woman on the planet is inspired
        by you.
      </p>
    </section>

    <section className="pa6-ns pv5 white mv5 bg-dusty-rose pa3 ph5-ns tc">
      <h2 className="f3 mb5 lh-title mb3">Here’s how this is gonna go down.</h2>

      <div
        style={{ height: "200px" }}
        className="f4 flex flex-column justify-between brandon-light"
      >
        <div>1. Write a love note (or 10)</div>
        <div>2. We roll it into our love note collection</div>
        <div>3. We send you an extra special gift to thank you</div>
        <div>4. No strings attached</div>
      </div>
    </section>

    <section className="pa4-ns black mh3 mv5 bg-white pa3 ph5-ns tc">
      <h2 className="f1 lh-title">Easy Breezy.</h2>

      <p className="f4 mt4 dib lh-copy brandon-light w-60-ns w-100">
        We’ll get the love note over to our Empower and Rise editorial gal and
        send your love note to thousands of other women. I have a feeling you
        are about to send some serious Empower and Rise through the interwebs!
        Love big. <br /> &lt;wink&gt;
      </p>

      <div className="mt5 mb4 tracked brandon-bold f4 rust">
        READY SET...GIVE LOVE!
      </div>

      <form
        acceptCharset="UTF-8"
        className="brandon-light"
        action="https://iv153.infusionsoft.com/app/form/process/15838284218a43e21bd5f90801f58265"
        id="inf_form_15838284218a43e21bd5f90801f58265"
        method="POST"
      >
        <input
          name="inf_form_xid"
          type="hidden"
          value="15838284218a43e21bd5f90801f58265"
        />
        <input
          name="inf_form_name"
          type="hidden"
          value="E+R Love Notes &#a;Submission Form"
        />
        <input name="infusionsoft_version" type="hidden" value="1.70.0.123227" />
        <div className="infusion-field">
          <label hidden htmlFor="inf_field_FirstName">
            First Name
          </label>
          <input
            className="w-100 f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-50-ns center"
            id="inf_field_FirstName"
            name="inf_field_FirstName"
            placeholder="First Name"
            type="text"
          />
        </div>
        <div className="infusion-field">
          <label hidden htmlFor="inf_field_LastName">
            Last Name
          </label>
          <input
            className="w-100 f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-50-ns center"
            id="inf_field_LastName"
            name="inf_field_LastName"
            placeholder="Last Name"
            type="text"
          />
        </div>
        <div className="infusion-field">
          <label hidden htmlFor="inf_field_Email">
            Email
          </label>
          <input
            className="w-100 f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-50-ns center"
            id="inf_field_Email"
            name="inf_field_Email"
            placeholder="Email"
            type="text"
          />
        </div>
        <input name="inf_custom_FavoritePlant1" type="hidden" value="null" />
        <div className="infusion-field">
          <label hidden htmlFor="inf_custom_LoveNoteSubmissions">
            Love Note Submissions *
          </label>
          <textarea
            className="w-100 f5 f4-ns bn bg-gray input-reset ba b--black-20 mb2 pa2 db w-50-ns center"
            cols="24"
            id="inf_custom_LoveNoteSubmissions"
            name="inf_custom_LoveNoteSubmissions"
            placeholder="Love Notes"
            rows="5"
          />
        </div>
        <div className="brandon-light mv4 f4">
          That’s it! <br />
          <br />
          Your love is ready to send.
        </div>
        <div className="infusion-submit">
          <button
            className="pointer f7 w-50-ns w-100 link ph4 pv3 mt2 mb4 dib white bg-black brandon-bold tracked infusion-recaptcha"
            id="recaptcha_15838284218a43e21bd5f90801f58265"
            type="submit"
          >
            SEND LOVE!
          </button>
        </div>
      </form>
      <script type="text/javascript" src="https://iv153.infusionsoft.app/app/webTracking/getTrackingCode"></script>
      <script type="text/javascript" src="https://iv153.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.89421"></script>
      <script src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit" async="async" defer="defer"></script>
      <script type="text/javascript" src="https://iv153.infusionsoft.com/app/timezone/timezoneInputJs?xid=7bc5bffde428819322b772338477eadc"></script>

      <div className="brandon-light mv4 f4">
        <div className="flex flex-column justify-center">
          <div className="mb4 lh-copy">
            Giving love doesn’t cost a lot, but Woman, does it go far.
          </div>
          <div className="mb4">You are an inspiration.</div>
          <div className="mb4">Thank you.</div>
          <div className="mb4">Love,</div>
        </div>

        <h1 className="xtreem-thin f1">Empower and Rise</h1>

        <div className="mt5 w-40-ns w-100 center lh-copy">
          P.S. You are just a love, empower, and rise machine. Power on! We are
          sending you your special gift + love notes daily as thanks. Unsub
          anytime.
        </div>
      </div>
    </section>
  </Layout>
)

export default GiveLovePage
